<template>
  <v-container class="w-100" fluid>
    <p class="text-center title mt-6 mb-0">
      {{
        $vuetify.lang.t("$vuetify.main.adminDashboard.psychologists.mainTitle")
      }}
    </p>
    <v-card class="mt-3">
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.tabs.inactivePsychologists"
            )
          }}</v-tab
        >
        <v-tab>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.tabs.activePsychologists"
            )
          }}</v-tab
        >
        <div class="align-self-center ml-auto pr-4">
          <a @click="downloadDocument" class="white--text">{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.buttons.downloadAll"
            )
          }}</a>
        </div>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-if="!inactiveLoadingDone">
          <v-row class="pt-16 pb-16">
            <v-col cols="12" md="12 text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item v-else>
          <psychologistCards
            @reload="reload"
            :psychologists="inactivePsychologists"
            inactive
          />
        </v-tab-item>
        <v-tab-item v-if="!activeLoadingDone">
          <v-row class="pt-16 pb-16">
            <v-col cols="12" md="12 text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item v-else>
          <psychologistCards
            @reload="reload"
            :psychologists="activePsychologists"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import psychologistCards from "@/components/dashboard/adminDashboard/psychologistCards";
export default {
  name: "psychologistsComponent",
  components: {
    psychologistCards,
  },
  metaInfo() {
    return {
      title:
        "Платформа / " +
        this.$vuetify.lang.t("$vuetify.main.header.menu.psychologists"),
    };
  },
  data() {
    return {
      tab: null,
      activeLoadingDone: false,
      inactiveLoadingDone: false,
      // activeLoadingDone: true,
      // inactiveLoadingDone: true,
    };
  },
  beforeCreate() {
    this.$store.dispatch("user/requestPsychologistCards");
  },
  watch: {
    activePsychologists() {
      this.activeLoadingDone = true;
    },
    inactivePsychologists() {
      this.inactiveLoadingDone = true;
    },
  },
  computed: {
    activePsychologists() {
      return this.$store.getters["user/getActivePsychologistForms"];
    },
    inactivePsychologists() {
      return this.$store.getters["user/getInactivePsychologistForms"];
    },
  },
  methods: {
    reload() {
      this.activeLoadingDone = false;
      this.inactiveLoadingDone = false;
    },
    downloadDocument() {
      this.$store.dispatch("user/downloadPsychologistInfo");
    },
  },
};
</script>
<style lang="scss" scoped></style>
