import AuthService from "../../services/api/auth.services";
import RegistrationService from "../../services/api/registration.services";
import AppServices from "@/services/api/app.services";
import AdminServices from "@/services/api/admin.services";
import PsychologistServices from "@/services/api/psychologist.services";
import RedactorServices from "../../services/api/redactor.services";
import PaymentServices from "../../services/api/payment.services";

const initialUserState = {
  authToken: null,
  name: "",
  lastName: "",
  patronymic: "",
  age: "",
  dateOfBirth: "",
  role: "",
  phoneCountryCode: "",
  phone: "",
  email: "",
  verificationEmail: false,
  verificationCard: false,
  timeZone: "(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
  totalUserAnswers: "",
  patient: {},
  psychologistsList: {},
  userSettings: {
    notificationPerHour: false,
    notificationPerHours: null,
  },
  patientQuestionnaire: {
    questionnaire: {
      name: "",
      age: "",
      has_therapy_experience: "",
      timezone: "",
      profile_id: "",
      calendar_id: "",
      psychologistName: "",
      psychologistSurname: "",
      selectedSlot: {
        date: {
          translated: "",
        },
        slot: {
          time: "",
        },
      },
    },
    general_questions: {},
  },
  psychologistQuestionnaire: {
    photo: [],
    certificates: [],
    profile: {
      timezone: "(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    },
    general_questions: {},
  },
  psychologist: {},
};

let state = {
  testPhoto: "",
  isAuth: false,
  authToken: null,
  sessionId: null,
  sessionCookie: null,
  authCode: null,
  psychologists: [],
  admin: {
    activeList: [],
    inactiveList: [],
    redactorList: {},
  },
  payment: {
    credential: {
      data: {},
    },
  },
  user: {
    admin: false,
    authToken: null,
    name: "",
    lastName: "",
    patronymic: "",
    age: "",
    dateOfBirth: "",
    role: "",
    locale: "uk",
    phoneCountryCode: "",
    phone: "",
    email: "",
    verificationEmail: false,
    verificationCard: false,
    timeZone: "(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    totalUserAnswers: "",
    patient: {},
    psychologistsList: {},
    userSettings: {
      notificationPerHour: false,
      notificationPerHours: null,
    },
    patientQuestionnaire: {
      questionnaire: {
        name: "",
        age: "",
        has_therapy_experience: "",
        timezone: "",
        profile_id: "",
        calendar_id: "",
        psychologistName: "",
        psychologistSurname: "",
        selectedSlot: {
          date: {
            translated: "",
          },
          slot: {
            time: "",
          },
        },
      },
      general_questions: {},
    },
    psychologistQuestionnaire: {
      photo: [],
      certificates: [],
      profile: {
        timezone: "(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
      },
      general_questions: {},
    },

    psychologist: {},
    chosenPsychologistId: null,
  },
};

const mutations = {
  SET_ADMIN_TRIGGER(state, payload) {
    state.user.admin = payload;
  },
  CHANGE_LOCALE(state, payload) {
    state.user.locale = payload;
  },
  SET_PATIENT(state, payload) {
    state.user.patient = payload;
  },
  SET_TOTAL_USER_ANSWERS(state, payload) {
    state.user.totalUserAnswers = payload;
  },
  SWITCH_USER_ROLE(state, role) {
    state.user.role = role;
  },
  SET_LOCALE(state, locale) {
    state.user.locale = locale;
  },
  SET_USER_PHONE(state, payload) {
    state.user.phone = payload.phone;
    state.user.phoneCountryCode = payload.code;
  },
  SET_USER_ROLE(state, payload) {
    state.user.role = payload;
  },
  SET_PSYCHOLOGISTS(state, payload) {
    state.psychologists = payload;
  },
  SET_USER(state, payload) {
    state.user = Object.assign(state.user, payload.data);
    state.isAuth = true;
    state.authToken = payload.access_token;
  },
  UPDATE_USER(state, payload) {
    state.user = payload.data;
  },
  SET_AUTH_TOKEN(state, payload) {
    state.isAuth = true;
    state.authToken = payload;
  },
  SET_LOGOUT(state) {
    state.isAuth = false;
    state.authToken = null;
    state.payment = { credential: { data: {} } };
    state.admin = {
      activeList: [],
      inactiveList: [],
      redactorList: {},
    };
    state.authCode = null;
    // state.user = JSON.parse(JSON.stringify(initialUserState));
    state.user = { ...initialUserState };
  },
  SET_USER_VERIFY(state) {
    state.user.is_verified = true;
  },
  SET_USER_EMAIL(state, payload) {
    state.user.email = payload;
  },
  SET_SESSION_COOKIE(state, payload) {
    state.sessionCookie = payload;
  },
  SET_AUTH_CODE(state, payload) {
    state.authCode = payload;
  },
  SET_PATIENT_GENERAL_QUESTIONS(state, payload) {
    state.user.patientQuestionnaire.general_questions = payload;
  },
  SET_PSYCHOLOGIST_QUESTIONNAIRE_PROFILE(state, payload) {
    state.user.psychologistQuestionnaire.profile = Object.assign(
      state.user.psychologistQuestionnaire.profile,
      payload
    );
  },
  SET_PSYCHOLOGIST_QUESTIONNAIRE_GENERAL(state, payload) {
    state.user.psychologistQuestionnaire.general_questions = Object.assign(
      state.user.psychologistQuestionnaire.general_questions,
      payload
    );
  },
  SET_PATIENT_QUESTIONNAIRE_NAME(state, payload) {
    state.user.patientQuestionnaire.questionnaire.name = payload;
  },
  SET_PATIENT_QUESTIONNAIRE_AGE(state, payload) {
    state.user.patientQuestionnaire.questionnaire.age = payload;
  },
  SET_PATIENT_QUESTIONNAIRE_THERAPY_EXP(state, payload) {
    state.user.patientQuestionnaire.questionnaire.has_therapy_experience =
      payload;
  },
  SET_USER_TIMEZONE(state, payload) {
    state.user.timezone = payload;
  },
  SET_PATIENT_QUESTIONNAIRE_PROFILE_ID(state, payload) {
    state.user.patientQuestionnaire.questionnaire.profile_id = payload;
  },
  SET_PATIENT_QUESTIONNAIRE_PSYCHOLOGIST_NAME(state, payload) {
    state.user.patientQuestionnaire.questionnaire.psychologistName = payload;
  },
  SET_PATIENT_QUESTIONNAIRE_PSYCHOLOGIST_SURNAME(state, payload) {
    state.user.patientQuestionnaire.questionnaire.psychologistSurname = payload;
  },
  SET_PATIENT_QUESTIONNAIRE_PSYCHOLOGIST_PHOTO(state, payload) {
    state.user.patientQuestionnaire.questionnaire.psychologistPhoto = payload;
  },
  SET_PATIENT_QUESTIONNAIRE_CALENDAR_ID(state, payload) {
    state.user.patientQuestionnaire.questionnaire.calendar_id = payload;
  },
  SET_PATIENT_QUESTIONNAIRE_SELECTED_SLOT(state, payload) {
    state.user.patientQuestionnaire.questionnaire.selectedSlot = payload;
  },
  SET_PSYCHOLOGIST(state, payload) {
    state.user.psychologist = payload;
  },
  SET_PSYCHOLOGISTS_LIST(state, payload) {
    state.user.psychologistsList = payload;
  },
  SET_CARD(state, payload) {
    state.user.payment_method.card_holder = payload.payment_method.card_holder;
    state.user.payment_method.masked_method =
      payload.payment_method.masked_method;
  },
  SET_USER_PHOTO(state, payload) {
    state.user.psychologistQuestionnaire.photo = payload;
  },
  SET_USER_CERTIFICATES(state, payload) {
    state.user.psychologistQuestionnaire.certificates = payload;
  },
  SET_PSYCHOLOGISTS_CARDS(state, payload) {
    payload.forEach((response) => {
      for (let i of response.data.data) i.openQuestionaryForm = false;
      response.config.url === "api/admin/psychologists/active"
        ? (state.admin.activeList = response.data.data)
        : (state.admin.inactiveList = response.data.data);
    });
  },
  SET_ACTIVE_CARDS(state, payload) {
    state.admin.activeList = payload.data.data;
  },
  SET_INACTIVE_CARDS(state, payload) {
    state.admin.inactiveList = payload.data.data;
  },
  SET_CHOSEN_PSYCHOLOGIST_ID(state, payload) {
    state.user.chosenPsychologistId = payload;
  },
  SET_REDACTORS_LIST(state, payload) {
    state.admin.redactorList = payload;
  },
  SET_PAYMENT_CREDENTIALS(state, payload) {
    state.payment.credential = payload;
  },
  SET_PAYMENT_METHOD(state, payload) {
    state.user.payment_method = payload;
  },
};

const actions = {
  setPatientGeneralQuestions({ commit }, payload) {
    commit("SET_PATIENT_GENERAL_QUESTIONS", payload);
  },
  setPatientQuestionnaireName({ commit }, payload) {
    commit("SET_PATIENT_QUESTIONNAIRE_NAME", payload);
  },
  setPatientQuestionnaireAge({ commit }, payload) {
    commit("SET_PATIENT_QUESTIONNAIRE_AGE", payload);
  },
  setPatientQuestionnaireTherapyExp({ commit }, payload) {
    commit("SET_PATIENT_QUESTIONNAIRE_THERAPY_EXP", payload);
  },
  setUserTimezone({ commit }, payload) {
    commit("SET_USER_TIMEZONE", payload);
  },
  setPatientQuestionnaireProfileId({ commit }, payload) {
    commit("SET_PATIENT_QUESTIONNAIRE_PROFILE_ID", payload);
  },
  setPatientQuestionnaireCalendarId({ commit }, payload) {
    commit("SET_PATIENT_QUESTIONNAIRE_CALENDAR_ID", payload);
  },
  setPatientQuestionnairePsychName({ commit }, payload) {
    commit("SET_PATIENT_QUESTIONNAIRE_PSYCHOLOGIST_NAME", payload);
  },
  setPatientQuestionnairePsychSurname({ commit }, payload) {
    commit("SET_PATIENT_QUESTIONNAIRE_PSYCHOLOGIST_SURNAME", payload);
  },
  setPatientQuestionnairePsychPhoto({ commit }, payload) {
    commit("SET_PATIENT_QUESTIONNAIRE_PSYCHOLOGIST_PHOTO", payload);
  },
  setPatientQuestionnaireSelectedSlot({ commit }, payload) {
    commit("SET_PATIENT_QUESTIONNAIRE_SELECTED_SLOT", payload);
  },
  setQuestionnaireProfile({ commit }, payload) {
    commit("SET_PSYCHOLOGIST_QUESTIONNAIRE_PROFILE", payload);
  },
  setQuestionnaireGeneral({ commit }, payload) {
    commit("SET_PSYCHOLOGIST_QUESTIONNAIRE_GENERAL", payload);
  },
  setEmail({ commit }, payload) {
    commit("SET_USER_EMAIL", payload);
  },
  setUserPhoto({ commit }, payload) {
    commit("SET_USER_PHOTO", payload);
  },
  setUserCertificates({ commit }, payload) {
    commit("SET_USER_CERTIFICATES", payload);
  },
  setChosenPsychologistId({ commit }, payload) {
    commit("SET_CHOSEN_PSYCHOLOGIST_ID", payload);
  },
  setLocale({ commit }, payload) {
    commit("SET_LOCALE", payload);
  },
  // eslint-disable-next-line no-empty-pattern
  sendCode({}, data) {
    return new Promise((resolve, reject) => {
      AuthService.post("sendCode", data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  viberSendCode({}, data) {
    return new Promise((resolve, reject) => {
      AuthService.post(data.type, { phone: data.phone })
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  verifyCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      AuthService.post("verifyCode", data, {
        // headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          commit("SET_USER", response.data);
          commit("app/CHANGE_LOCALE", response.data.data.locale, {
            root: true,
          });
          return resolve(response);
        })
        .catch(({ errors }) => reject(errors));
    });
  },
  sendUserRegCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      RegistrationService.post("sendCode", data)
        .then((response) => {
          commit("SET_AUTH_CODE", response.data.auth_code);
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  verifyUserRegCode({}, data) {
    return new Promise((resolve, reject) => {
      RegistrationService.post("verifyCode", data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  sendPsychEmailCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      RegistrationService.post("sendEmailCode", data)
        .then(() => {
          commit("SET_AUTH_CODE", "ready");
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  verifyEmailCode({}, data) {
    return new Promise((resolve, reject) => {
      RegistrationService.post("verifyEmailCode", data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  getPsychologists({}, data) {
    return new Promise((resolve, reject) => {
      AppServices.get("getPsychologist", data)
        .then((response) => {
          const psychologists = response;

          return resolve(psychologists);
        })
        .catch((error) => reject(error));
    });
  },
  getProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      AppServices.get("getProfile", data)
        .then((response) => {
          commit("UPDATE_USER", response.data);

          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      AppServices.post("getProfile", {}, data)
        .then((response) => {
          if (response) {
            commit("UPDATE_USER", response.data);
          }

          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  updatePsychologistPhoto({ state }, data) {
    return new Promise((resolve, reject) => {
      AdminServices.post("updatePsychologistPhoto", data.get("userId"), data)
        .then((response) => {
          if (response) {
            state.admin.inactiveList.map((psychologist) => {
              if (psychologist.id === response.data.data.id) {
                psychologist.photo = response.data.data.photo;
              }
            });
          }

          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  psychologistRegistration({ commit }, data) {
    return new Promise((resolve, reject) => {
      RegistrationService.post("psychologistRegistration", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          commit("SET_USER", response.data);
          commit("app/CHANGE_LOCALE", response.data.data.locale, {
            root: true,
          });
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  psychologistEditProfile({}, { id, payload }) {
    return new Promise((resolve, reject) => {
      AdminServices.post("editPsychologist", id, payload)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  patientRegistration({ commit }, data) {
    return new Promise((resolve, reject) => {
      RegistrationService.post("patientRegistration", data, {})
        .then((response) => {
          commit("SET_USER", response.data);
          commit("app/CHANGE_LOCALE", response.data.data.locale, {
            root: true,
          });
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  requestListPsychologists({ commit }, data) {
    return new Promise((resolve, reject) => {
      RegistrationService.post(
        "requestListPsychologists",
        data,
        {},
        data.chosenPsychologist
      )
        .then((response) => {
          commit("SET_PSYCHOLOGISTS_LIST", response.data);
          commit("SET_TOTAL_USER_ANSWERS", response.total_user_answers);
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  createCard({}, data) {
    return new Promise((resolve, reject) => {
      AppServices.post("createCard", {}, data)
        .then(() => {
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },

  deleteCard({ commit }, data) {
    return new Promise((resolve, reject) => {
      AppServices.delete("deleteCard", data)
        .then((response) => {
          commit("UPDATE_USER", response.data);
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  requestPsychologistCards({ commit }, arrRequests) {
    let requestActiveList = AdminServices.get("activePsychologists");
    let requestInactiveList = AdminServices.get("inactivePsychologists");
    return Promise.all(
      arrRequests ? arrRequests : [requestActiveList, requestInactiveList]
    )
      .then((response) => {
        commit("SET_PSYCHOLOGISTS_CARDS", response);
        return response;
      })
      .catch((error) => error);
  },
  activatePsychologist({ dispatch, commit }, id) {
    return new Promise((resolve, reject) => {
      AdminServices.patch("activatePsychologist", id)
        .then((response) => {
          commit("SET_INACTIVE_CARDS", response);
          dispatch("requestPsychologistCards", [
            AdminServices.get("activePsychologists"),
          ]);
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  deactivatePsychologist({ dispatch, commit }, id) {
    return new Promise((resolve, reject) => {
      AdminServices.patch("deactivatePsychologist", id)
        .then((response) => {
          commit("SET_ACTIVE_CARDS", response);
          dispatch("requestPsychologistCards", [
            AdminServices.get("inactivePsychologists"),
          ]);
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  deletePsychologist({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      AdminServices.delete("deletePsychologist", id)
        .then((response) => {
          dispatch("requestPsychologistCards");
          return resolve(response);
        })
        .catch(reject);
    });
  },
  getPsychologistFilter() {
    return new Promise((resolve, reject) => {
      AppServices.get("getPsychologistFilter")
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  downloadPsychologistInfo() {
    return new Promise((resolve, reject) => {
      AdminServices.get("downloadPsychologistsInfo")
        .then((response) => {
          const a = document.createElement("a");
          a.href =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            response.data.data.encoded;
          a.download = response.data.data.filename;
          a.click();
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  bookTimeSlotByPatient({}, data) {
    return new Promise((resolve, reject) => {
      AppServices.patch("bookTimeSlotByPatient", data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  cancelTimeSlotByPatient({}, data) {
    return new Promise((resolve, reject) => {
      AppServices.patch("cancelTimeSlotByPatient", data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  allowTimeSlotByPsyh({}, data) {
    return new Promise((resolve, reject) => {
      PsychologistServices.post("allowTimeSlot", {}, data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  changeLocale({ commit, state }, data) {
    commit("CHANGE_LOCALE", data);
    if (state.isAuth) {
      return new Promise((resolve, reject) => {
        let payload = { locale: data };
        AppServices.post("switchLocale", {}, payload, {})
          .then((response) => {
            location.reload();
            return resolve(response);
          })
          .catch(({ errors }) => reject(errors));
      });
    }
  },
  getRedactorsList({ commit }) {
    return new Promise((resolve, reject) => {
      RedactorServices.get("getRedactorsList", {})
        .then((response) => {
          commit("SET_REDACTORS_LIST", response.data.data);
          return resolve(response);
        })
        .catch(({ errors }) => reject(errors));
    });
  },
  createNewRedactor({ commit }, data) {
    return new Promise((resolve, reject) => {
      RedactorServices.post("createRedactor", {}, data)
        .then((response) => {
          commit("SET_REDACTORS_LIST", response.data.data);
          return resolve(response);
        })
        .catch(({ errors }) => reject(errors));
    });
  },
  deleteRedactor({ commit }, params) {
    return new Promise((resolve, reject) => {
      RedactorServices.delete("deleteRedactor", params)
        .then((response) => {
          commit("SET_REDACTORS_LIST", response.data.data);
          return resolve(response);
        })
        .catch(({ errors }) => reject(errors));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  cancelTimeSlotByPsyh({}, data) {
    return new Promise((resolve, reject) => {
      PsychologistServices.patch("cancelTimeSlot", data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getPaymentCredentials({ commit }, data) {
    return new Promise((resolve, reject) => {
      PaymentServices.get("getCredentials", {}, data)
        .then((response) => {
          commit("SET_PAYMENT_CREDENTIALS", response.data);
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  sendPaymentCredentials({ commit }, data) {
    return new Promise((resolve, reject) => {
      PaymentServices.post("sendCredentials", {}, data)
        .then((response) => {
          commit("UPDATE_USER", response.data);
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  sendRatingAfterSession({}, data) {
    return new Promise((resolve, reject) => {
      AppServices.post("sendRating", { id: data.id }, { rating: data.rating })
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getPsychologists: (state) => state.psychologists,
  getUser: (state) => state.user,
  getAdminTrigger: (state) => state.user.admin,
  getAuthToken: (state) => state.authToken,
  getAuthParam: (state) => {
    return {
      isAuth: state.isAuth,
      authToken: state.authToken,
      userRole: state.user.role,
    };
  },

  getPatientGeneralQuestions: (state) =>
    state.user.patientQuestionnaire.general_questions,
  getUserPhone: (state) => state.user.phone,
  getUserPhoneCountryCode: (state) => state.user.phoneCountryCode,
  getUserEmail: (state) => state.user.email,
  getUserPhoto: (state) => state.user.psychologistQuestionnaire.photo,
  getCertificates: (state) => state.user.psychologistQuestionnaire.certificates,
  getUserQuestionnaireGeneralQuestions: (state) =>
    state.user.psychologistQuestionnaire.general_questions,
  getUserQuestionnaireProfile: (state) =>
    state.user.psychologistQuestionnaire.profile,
  isAuth: (state) => state.isAuth,
  getActivePsychologistForms: (state) => state.admin.activeList,
  getInactivePsychologistForms: (state) => state.admin.inactiveList,
  getChosenPsychologistId: (state) => state.user.chosenPsychologistId,
  getPsychologistsList: (state) => state.user.psychologistsList,
  getPatientQuestionnaire: (state) => state.user.patientQuestionnaire,
  getPayCredentials: (state) => state.payment.credential,
  getRedactorsList: (state) => state.admin.redactorList,
  getSelectedLocale: (state) => state.user.locale,
  getAttachedPsychologist: (state) => state.user.attached_psychologist,
  // getPsychologistQuestionnaire: (state) => state.patient.psychologistQuestionnaire,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
