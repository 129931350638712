import ApiClient from "@/libs/http-client";

const AdminService = {
  endpoints(route, params) {
    const url = {
      activePsychologists: "api/admin/psychologists/active",
      inactivePsychologists: "api/admin/psychologists/inactive",
      activatePsychologist: `api/admin/psychologists/${params}/activate`,
      deactivatePsychologist: `api/admin/psychologists/${params}/deactivate`,
      downloadPsychologistsInfo: `api/admin/psychologists/export`,
      updatePsychologistPhoto: `api/admin/psychologists/${params}`,
      deletePsychologist: `api/admin/psychologists/${params}`,
      editPsychologist: `api/admin/psychologists/${params}`,
    };
    return url[route];
  },

  get(url, param) {
    return ApiClient.get(this.endpoints(url), param);
  },
  post(url, params = {}, data, config = {}) {
    return ApiClient.post(this.endpoints(url, params), data, config);
  },
  patch(url, params = {}, data, config = {}) {
    return ApiClient.patch(this.endpoints(url, params), data, config);
  },
  delete(url, params = {}, data, config = {}) {
    return ApiClient.delete(this.endpoints(url, params), data, config);
  },
};

export default AdminService;
