import ru from "vuetify/lib/locale/ru";

export default {
  ...ru,
  locale: {
    switcherLabel: "Выберите язык",
  },
  main: {
    paymentAlert: `У Вас есть запланированная сессия на {0}. Для проведения планового платежа за 12 часов до начала сенаса привяжите, пожалуйста, карту.`,
    route: {
      enter: "ВХОД",
      registration: "РЕГИСТРАЦИЯ",
    },
    logout: "Вы успешно были разлогинены!",
    header: {
      menu: {
        main: "Главная",
        settings: "Настройки",
        specialization: "Специализация",
        schedule: "Расписание",
        blog: "Блог",
        psychologists: "Психологи",
        categories: "Категории",
        exit: "Выход",
        payment: "Оплата",
        ourPsychologists: "Наши терапевты",
      },
    },
    redactorDashboard: {
      redactors: {
        title:
          "В данном разделе вы можете создать/удалить профиль редактора для блога",
        createRedactor: "Создать профиль редактора",
        delete: "Удалить",
        cancel: "Отмена",
        accept: "Применить",
        name: "Имя*",
      },
      blog: {
        title:
          "В данном разделе вы можете создать новую статью или редактировать ранее созданную",
        allBlogs: "Все ранее созданные статьи:",
        create: "Создать",
        all: "Все",
        published: "Опубликованные",
        waiting: "Запланированные",
        draft: "Черновики",
        langDesk: "Каждую статью возможно создать на 2 языках",
        cancel: "Отмена",
        accept: "Применить",
        createBlog: "Создать статью",
        editBlog: "Редактировать статью",
        publishTime: "Время публикации",
        publishDate: "Дата публикации",
        publishNow: "Опубликовать сейчас",
        previewPhoto: "Фото для заголовка",
      },
      categories: {
        title: "В данном разделе вы можете создать/удалить категорию для блога",
        delete: "Удалить",
        create: "Создать",
        createCategory: "Создать новую категорию",
        createdCategories: "Созданные категории:",
      },
    },
    adminDashboard: {
      psychologists: {
        month: "Месяц",
        mainTitle:
          "В данном разделе вы можете активировать/деактивировать психологов на платформе",
        changePhotoRequire: "Фото профиля требует подтверждения",
        changePhotoWarning: "Необходимо загрузить фото",
        changePhotoDone: "Фото профиля подтверждён",
        rating: "Рейтинг",
        current: "Текущий",
        last: "Прошлый",
        swalText: {
          deleted: "Пользователь успешно удален",
          activated: "Анкета активирована. Выполняется обновление списков...",
          deactivated:
            "Анкета деактивирована. Выполняется обновление списков...",
          updated: "Данные обновлены",
          invalidPhone: "Указан не валидный номер телефона",
        },
        tabs: {
          inactivePsychologists: "Не активные психологи",
          activePsychologists: "Активные психологи",
        },
        buttons: {
          confirmUpdates: "Подтвердите изменения",
          downloadPhoto: "Скачать фото",
          uploadPhoto: "Загрузить фото",
          downloadAll: "Скачать все",
          download: "Скачать",
          activate: "Активировать",
          deactivate: "Деактивировать",
          apply: "Применить",
          viewProfile: "Посмотреть анкету",
          back: "Назад",
          forward: "Далее",
          closeForm: "Закрыть анкету",
          btnEdit: "Редактировать",
          btnCancel: "Отменить",
          btnSave: "Сохранить",
          confirmDelete: "Вы действительно хотите удалить",
          confirmActivate: "Активировать анкету",
          confirmDeactivate: "Деактивировать анкету",
          confirmY: "Подтвердить",
          confirmN: "Отменить",
        },
        switcherLabel: {
          yes: "Да",
          no: "Нет",
        },
        stepsTitle: {
          generalInformation: "Общая информация",
          mainTreatmentMethod: "Основной метод",
          workExperience: "Опыт работы",
          aboutInfo: "О себе",
        },
        inputLabel: {
          main: {
            uploadPhoto: "Загрузить фото",
            stepOne: {
              firstName: "Имя",
              secondName: "Фамилия",
              patronymic: "Отчество",
              dateOfBirth: "Дата рождения",
              citizenship: "Гражданство",
              phone: "Телефон",
              university: "Название ВУЗа",
              faculty: "Название факультета",
              speciality: "Специальность",
              scienseDegree: "Академическая или ученая степень",
              yearOfEnding: "Год окончания",
            },
            stepThree: {
              workExperience: "Опыт работы",
              additionalProgram:
                "Полное название и количество пройденных часов",
            },
            stepFour: {
              from: "От",
              to: "До",
            },
          },
          questionnaire: {
            title: "Анкета",
            answer: "Ответ...",
            stepOne: {
              education: "Образование",
              certificatesTitle:
                "Фотографии развернутых дипломов и сертификатов, подтверждающих обучение:",
            },
            stepTwo: {
              mainTreatmentMethod: "Основной метод",
              additionalEducation: "Дополнительное образование",
              problems: "Проблемы с которыми работает",
            },
            stepThree: {
              workExperience:
                "Сколько лет вы консультируете? За деньги, не в рамках учебной программы.",
              additionalProgram:
                "Какие дополнительные программы прошли или проходите на данный момент?",
              selfTherapy: "Проходите ли вы личную психотерапию?",
              regularSupervision: "Проходите ли вы регулярно супервизии?",
            },
            stepFour: {
              aboutInfo:
                "Расскажите о себе в свободной форме. Что считаете нам нужно узнать о вас, чтобы понять, какой вы специалист?",
              warning: "Эта информация будет отображаться на сайте",
              notWorkingWith: "С чем вы не работаете?",
              FOP: "Зарегистрирован как ФОП",
              infoChannel:
                'Как вы узнали о наборе терапевтов в сервис "Платформа"?',
              clientsQuantity:
                'Как количество клиентов вы готовы взять на "Платформа"?',
              profileLinks: "Укажите ссылки на профили в социальных сетях ",
            },
          },
        },
      },
    },
    psychologistDashboard: {
      settings: {
        imageInput: "Загрузить новое фото",
        notificationLabel: {
          1: "За 1 час",
          2: "За 2 час",
          5: "За 5 часов",
          12: "За 12 часов",
          24: "За 24 часа",
        },
        mainSettings: "Главные настройки",
        aboutInfo: "О себе",
        education: "Образование",
        workExperience: "Опыт работы",
        certificates: "Сертификаты",

        aboutYou: "О вас",
        yourAvatar: "Ваша аватарка:",
        avatarDesc:
          "Ваша аватарка находится на рассмотрении Администрации, это не занимает много времени.",
        avatarAlt: "Аватар психолога",
        name: "Ваше имя:",
        nameLabel: "Имя",
        lastName: "Ваша фамилия:",
        lastNameLabel: "Фамилия",
        patronymic: "Ваше отчество:",
        patronymicLabel: "Отчество",

        timezone: "Ваш часовой пояс:",
        howOld: "Сколько вам лет ?",
        birthday: "Дата рождения",
        FOP: "Вы зарегистрированы как ФОП?",
        FOPLabel: "Укажите реквизиты",

        contacts: "Контакты и настройки уведомлений",
        phone: "Телефон",
        email: "Эл. Почта",
        emailLabel: "Почта",

        about_info:
          "Расскажите о себе в свободной форме. Что считаете нам нужно знать о вас, чтобы понять, какой Вы специалист ? Эта информация будет отображаться на сайте.",
        aboutInfoLabel: "О себе:",
        updateInfo1: "Учтите, данную информацию",
        updateInfo2: " можно редактировать строго 1 раз в месяц.",
        lastUpdate: "Последний раз эта информация изменялась",

        consultingExperience:
          "Сколько лет Вы консультируете? За деньги, не в рамках учебной программы.",
        consultingExperienceLabel: "Опыт работы",
        additionalProgram:
          "Какие дополнительные программы прошли или проходите на данный момент?",
        coursesLabel: "Полное название и количество пройденных часов",
        selfTherapy: "Проходите ли Вы личную терапию?",
        regularSupervision: "Проходите ли Вы регулярно супервизии?",
        yes: "Да",
        no: "Нет",
        save: "Сохранить",
        update: "Обновить",
        yourCertificates: "Ваши сертификаты",
        uploadDoc: "Загрузить документ",
        cancel: "Отменить",
        accept: "Подтвердить",
        download: "Скачать",
        delete: "Удалить",

        rules: "Поле обязательно для заполнения.",
        nameRules: 'Поле "Имя" обязательно при заполнении',
        lastnameRules: 'Поле "Фамилия" обязательно при заполнении',
        emailRules1: 'Поле "E-mail" обязательно при заполнении',
        emailRules2: "E-mail должен быть валидным",
        entrepreneurRules1: "Это поле обязательно при заполнении",
        entrepreneurRules2: "Это поле должно содержать не менее 10 символов",
        consultingExperienceRules1: "Пол обязательно при заполнении",
        consultingExperienceRules2: "Некорректный ввод",
        consultingExperienceRules3: "Пожалуйста введите правдивые данные",
        coursesRules1: "Поле обязательно при заполнении",
        coursesRules2: "Это поле должно содержать не менее 10 символов",
        countrySelectorLabel: "Код страны",
        countrySelectorError: "Ошибка странны",
        phoneNumberLabel: "Номер телефона",
        example: "Пример :",
        year: "год",
        years: "лет",
        years2: "года",
        error: "Вы еще не можете изменять это поле.",
      },
      mainPage: {
        currentClient: "Текущий клиент:",
        clientsProblem: "Проблемы клиента:",
        rating: "Ваш рейтинг:",
        nextSession: "Ближайшая сессия:",
        skipNextSession: "Отменить сессию",
        todayClients: "Ваши клиенты на сегодняшний день",
        webRTC: {
          connection: "Подключаемся...",
          nextSessionWillBee: "Следующая сессия будет",
          textInformationInVideoPlayer:
            "В момент начала Вашего сеанса, кнопка для подключения к звонку появится ниже.",
          connectToCallBtn: "Присоединится к звонку",
          rateTheSession: "Оцените сессию",
          rateBtn: "Оценить",
          settings: "Настройки",
          scanningNextSession: "Сканируем..",
          psychologistSpinnerMessage:
            "Клиент еще не подключился, пожалуйста ожидайте...",
          clientSpinnerMessage:
            "Психолог еще не подключился, пожалуйста ожидайте...",
          remoteStatusVueSwall: "присоединяется",
          warningCallWillFinishVueSwall:
            "ВНИМАНИЕ! Звонок закончится через 5 минут.",
        },
      },
      welcomeMessage: {
        hello: "Здравствуйте,",
        title: "Рады приветствовать в вашем рабочем кабинете на Платформе",
        subtitle: "Ваша анкета сейчас находится на рассмотрении.",
        description:
          "Это значит, что вы не можете вести деятельность, пока ваш профиль не будет подтвержден администратором!",
        informing:
          "После рассмотрения анкеты (может рассматриваться до 2-х недель) мы свяжемся с вами и договоримся о собеседовании.",
        informing2: "Следите за электронной почтой.",
      },
      schedule: {
        swallCancelSessionByPsychologist: "Отменяем сеанс..",
        swallCancelSessionByPsychologistTitle:
          "Вы уверены, что хотите отменить сеанс ?",
        swallYes: "Да",
        swallNo: "Нет",
        client: "Клиент: ",
        instruction: "Инструкция",
        example1: "Пример 1",
        exampleDesk11: "- Время, когда вы ",
        exampleDesk12: "Не Готовы ",
        exampleDesk13: "работать (клиенту не отображается это время)",
        example2: "Пример 2",
        exampleDesk21: "- Время, когда вы ",
        exampleDesk22: "Готовы ",
        exampleDesk23: "работать (клиенту отображаетс это время для записи)",
        example3: "Пример 3",
        exampleDesk31:
          "- Время, забронированное клиеном для работі. У вас есть возможность отминить косультацию, так как до неё еще больше чем 24 часа",
        // exampleDesk32: "Вы не отображаетесь ",
        // exampleDesk33:
        //   "другим клиентам. А также у Вас есть возможность отменить сеанс, так-как до сеанса еще более 24 часов.",
        example4: "Пример 4",
        exampleDesk41:
          "- Время, забронированное клиентом для работы. Но вы не имеете возможности отменить консультацию, так как до неё осталось меньше чем 24 часа",
        // exampleDesk42: "Вы также не отображаетесь ",
        // exampleDesk43:
        //   "другим клиентам . А также у Вас нет возможности отменить сеанс, так-как до сеанса менее 24 часов.",
      },
      specialization: {
        title: "В данном разделе вы можете отредактировать анкетные данные",
        subtitle: "Выберите, проблемы с которыми вы можете помочь:",
        main_treatment_method: "Выберите Ваш основной метод:",
        additionalEducation:
          " Выберите, если у вас есть дополнительное образование, касающееся следующих тем:",
        other: "Другое",
        anotherSelectTextArea:
          "Напишите, пожалуйста, в какой области у вас есть дополнительное образование?",

        art_therapy: "Арт-терапия",
        positive_psychotherapy: "Позитивная психотерапия",
        client_centered_psychotherapy: "Клиент-центрированная психотерапия",
        cognitive_behavioral_therapy: "Когнитивно-поведенческая терапия",
        body_therapy: "Телесная терапия",
        psychoanalysis: "Психоанализ",
        jungian_analysis: "Юнгианский анализ",
        transactional_analysis: "Транзактный анализ",
        family_therapy: "Семейная терапия",
        gestalt_therapy: "Гештальт-терапия",
        psychodrama: "Психодрама",
        analytical_psychology: "Аналитическая психология",
        symboldrama: "Символдрама",
        existential_analysis: "Экзистенциальный анализ",

        addictions: "Зависимости",
        sexology: "Сексология",
        extreme_situations: "Экстремальные ситуации, ПТСР",

        // psychologist filters
        life_event_problems: "События жизни",
        mental_health_condition_problems: "Состояние",
        relationship_problems: "Отношения",
        career_problems: "Работа, карьера, учеба",
        /******************************************/

        relocate_emigration: "Переезд, эмиграция",
        financial_changes: "Финансовые изменения",
        betrayal: "Измена",
        illness: "Болезнь, своя или близких",
        pregnancy: "Беременность, рождение ребёнка",
        divorce: "Разрыв отношений, развод",
        violence: "Насилие",
        loss_of_a_loved_one: "Утрата близкого человека",

        stress: "Стресс",
        panic_attacks: "Панические атаки",
        low_self_esteem: "Низкая самооценка",
        sleep_problems: "Проблемы со сном",
        emotional_addiction: "Эмоциональная зависимость",
        hypochondria: "Ипохондрия, переживание о здоровье",
        alcohol_drugs_addiction: "Зависимость, от алкоголя, наркотиков",

        anxiety: "Тревожность",
        breakdown: "Упадок сил",
        mood_swings: "Перепады настроения",
        irritability: "Раздражительность",
        feelings_of_loneliness: "Ощущение одиночества",
        social_network_addiction: "Зависимость от социальных сетей",
        eating_disorders: "Расстройство пищевого поведения",

        with_partner: "С партнёром",
        with_children: "С детьми",
        sexual: "Сексуальные",

        with_parents: "С родителями",
        common: "В целом, с окружающими",

        stress_at_work: "Стресс, выгорание",
        procrastination: "Прокрастинация",
        no_goal: "Отсутствие цели",

        lack_of_motivation: "Недостаток мотивации",
        cannot_find_myself: '"Не знаю, чем хочу заниматься"',
        job_loss_change: "Смена, потеря работы",

        save_changes: "Сохранить изменения",
      },
    },
    patientDashboard: {
      blog: {
        header:
          "Мудрый совет, интересная история, список полезных лайфхаков или научная статья. Кто знает, какое знание придется вам по душе сегодня?",
        subtitle1: "Хорошо, что у нас есть выбор.",
        subtitle2:
          "Лучшие статьи помогут найти ответы на непростые и очень важные вопросы, отдохнуть душой и познакомиться с мнением экспертов проекта.",
        andMore: "...и еще много интересного",
      },
      main: {
        userAgreementLink: "пользовательском соглашении.",
        swallCancelSession: "Отменяем предыдущий сеанс..",
        swallBookedSession: "Бронируем новый сеанс..",
        paymentBtn: "Привязать карту",
        paymentMethodWarning:
          "Сеанс будет автоматически отменён. Чтобы этого не случилось, привяжите карту для оплаты",
        hello: "Здравствуйте ",
        yourTherapist: "Ваш терапевт: ",
        nonTherapist: "На данный момент у Вас нет терапевта.",
        chooseTherapist: "Выбрать терапевта",
        switchTherapist: "Поменять терапевта",
        warning:
          "Для отображения полного функционала - требуется привязать карту.",
        connectCard: "Привязать карту",
        nextSession: "Следующая сессия",
        shiftSession: "Перенести сессию",
        shiftSessionOnSelectedTime: "Перенести сессию на",
        bookedSession: "Забронировать сессию",
        bookedSessionOnSelectedTime: "Забронировать сессию на",
        cancelSession: "Отменить сессию",
        warningSession1:
          "Переносить можно не позднее чем за 12 часов до начала сессии. Полные условия читайте в ",
        warningSession2: "пользовательском соглашениии ",
        and: "и ",
        warningSession3: "договоре оказания услуг ",
        withPsychologist: "с психологом ",
        cancel: "Отмена",
        shiftTo: "Перенести на ",
      },
      settings: {
        title: "О вас",
        name: "Ваше имя:",
        age: "Сколько вам лет?",
        contacts: "Контакты и настройки уведомлений",
        smsNotification: "СМС-напоминания за 1 час до начала сессии",
        hint: "Ваш e-mail используется для напоминания о предстоящих сессиях",
        blogLabel: "Новости сервиса и важное из блога",
        notificationLabel: "Напоминания по почте",
        timeLabel: "Выберите время",
        beforeSession: "до начала сеанса.",
        save: "Сохранить",
        refresh: "Обновить",
      },
      ourPsychologists: {
        oops: "Упс..мы ничего не смогли найти по твоему запросу: c",
        workExperience: "Опыт работы ",
        showFully: "Показать полностью..",
        chooseDateTime: "Выберите дату и время:",
        chooseTime: "Выбрать время",
        book: " Забронировать ",
        on: "на ",

        lifeEvent: "События жизни",
        condition: "Состояние",
        relationship: "Отношение",
        career: "Работа, карьера, учеба",

        stress: "Стресс",
        panic_attacks: "Панические атаки",
        low_self_esteem: "Низкая самооценка",
        sleep_problems: "Проблемы со сном",
        emotional_addiction: "Эмоциональная зависимость",
        hypochondria: "Ипохондрия, переживание о здоровье",
        alcohol_drugs_addiction: "Зависимость, от алкоголя, наркотиков",
        anxiety: "Тревожность",
        breakdown: "Упадок сил",
        mood_swings: "Перепады настроения",
        irritability: "Раздражительность",
        feelings_of_loneliness: "Ощущение одиночества",
        social_network_addiction: "Зависимость от социальных сетей",
        eating_disorders: "Расстройство пищевого поведения",

        with_partner: "С партнёром",
        with_children: "С детьми",
        sexual: "Сексуальные",
        with_parents: "С родителями",
        common: "В целом, с окружающими",

        stress_at_work: "Стресс, выгорание",
        procrastination: "Прокрастинация",
        no_goal: "Отсутствие цели",
        lack_of_motivation: "Недостаток мотивации",
        cannot_find_myself: '"Не знаю, чем хочу заниматься"',
        job_loss_change: "Смена, потеря работы",

        relocate_emigration: "Переезд, эмиграция",
        financial_changes: "Финансовые изменения",
        betrayal: "Измена",
        illness: "Болезнь, своя или близких",
        pregnancy: "Беременность, рождение ребёнка",
        divorce: "Разрыв отношений, развод",
        violence: "Насилие",
        loss_of_a_loved_one: "Утрата близкого человека",
      },
      payment: {
        month: "Месяц",
        year: "Год",
        cardInfo: "Информация о вашей карте",
        cardDesk:
          "Данная карта используется для оплаты консультаций с Вашим терапевтом.",
        untieCard: "Отвязать карту",
        untieCardDesk: "Ваша карта не привязана",
        bookInfo:
          "Для бронирования времени консультации необходимо привязать карту",
        connectCard: "Привязать карту ",
        cancel: "Отмена",

        cardNumber: "Номер карты",
        cardOwner: "Владелец карты",
        expireDate: "Срок действия",
      },
    },
    patientReg: {
      generalQuestions: "Общие вопросы",
      personalQuestions: "Личные вопросы",
      therapists: "Выбор терапевта",
      terms: "Условия оплаты",
      further: "Далее",
      back: "Назад",
      apply: "Подать заявку",
      select: "Выбрать",
      stepOne: {
        yourName: "Как вас зовут?",
        nameDesk: "Ваше имя будет доступно вашему психологу",
        nameLabel: "Имя",
        yourAge: "Сколько вам лет?",
        ageDesk: "Для получения услуг вы должны быть старше 16 лет",
        ageLabel: "Возраст",
        therapyExperience: "Был ли у вас опыт терапии?",
        yes: "Да",
        no: "Нет",
        timeZone: "Часовой пояс",
        patientNameRules: 'Поле "Имя" обязательно при заполнении',
        patientAgeRules1: 'Поле "Возраст" обязательно при заполнении',
        patientAgeRules2: "Для получения услуг вы должны быть старше 16 лет.",
        patientEmailRules1: 'Поле "E-mail" обязательно при заполнении',
        patientEmailRules2: "E-mail должен быть валидным",
      },
      stepTwo: {
        selectThemes:
          "Выберите темы, с которыми вы хотите поработать с психологом",
        warning: "Необходимо выбрать, хотя бы одну тему",
        whatDiscuss: "Что вы хотели бы обсудить в первую очередь?",
        lifeEvent: "События жизни",
        condition: "Состояние",
        relationship: "Отношение",
        career: "Работа, карьера, учеба",

        relocate_emigration: "Переезд, эмиграция",
        financial_changes: "Финансовые изменения",
        betrayal: "Измена",
        illness: "Болезнь, своя или близких",
        pregnancy: "Беременность, рождение ребёнка",
        divorce: "Разрыв отношений, развод",
        violence: "Насилие",
        loss_of_a_loved_one: "Утрата близкого человека",

        stress: "Стресс",
        panic_attacks: "Панические атаки",
        low_self_esteem: "Низкая самооценка",
        sleep_problems: "Проблемы со сном",
        emotional_addiction: "Эмоциональная зависимость",
        hypochondria: "Ипохондрия, переживание о здоровье",
        alcohol_drugs_addiction: "Зависимость, от алкоголя, наркотиков",

        anxiety: "Тревожность",
        breakdown: "Упадок сил",
        mood_swings: "Перепады настроения",
        irritability: "Раздражительность",
        feelings_of_loneliness: "Ощущение одиночества",
        social_network_addiction: "Зависимость от социальных сетей",
        eating_disorders: "Расстройство пищевого поведения",

        with_partner: "С партнёром",
        with_children: "С детьми",
        sexual: "Сексуальные",

        with_parents: "С родителями",
        common: "В целом, с окружающими",

        stress_at_work: "Стресс, выгорание",
        procrastination: "Прокрастинация",
        no_goal: "Отсутствие цели",
        lack_of_motivation: "Недостаток мотивации",
        cannot_find_myself: '"Не знаю, чем хочу заниматься"',
        job_loss_change: "Смена, потеря работы",
      },
      stepThree: {
        workExperience: "Опыт работы",
        worksWith: "Работает с",
        outOf: "из",
        topics: "тем Вашей анкеты",
        soon: "Ближайшее время:",
        selectSessionTime: "Выбрать время сессии",
        aboutSpecialist: "О специалисте",
        canContactMe: "Вы можете обратиться ко мне:",
        education: "Образование",
        therapyMethod: "Метод терапии",
        workTopics: "Специалист работает с темами из вашей анкеты:",
        dateAndTime: "Выберите дату и время сессии",
        canShift: "Можно перенести за 12 часов",
        price: "Фиксированная цена {0} ",
      },
      stepFour: {
        price: "Стоимость сессии составит ",
        termsPart1: "После того, как вы привяжете карту, мы снимем с неё",
        termsPart2:
          "и сразу же вернём обратно. Это стандартная процедура проверки.",
        termsPart31: "Деньги списываются с карты ",
        termsPart32: "за 12 часов ",
        termsPart33:
          "до сессии – и после того, как это произошло, вы уже не сможете её перенести или отменить.",
        termsPart41: "Вы в любой момент ",
        termsPart42: "можете отвязать ",
        termsPart43: "свою карту. Это можно сделать в личном кабинете.",
        termsPart5:
          "Все сессии проводятся по видеосвязи из личного кабинета и вам потребуется стабильное подключение к интернету.",
        important: "Важно! ",
        termsPart6: `Нажимая кнопку «Привязать карту» вы подтверждаете, что ознакомлены и согласны с договором оказания услуг и даёте согласие на обработку персональных данных психологу.`,
        psychologist: "Терапевт:",
        sessionDate: "Дата и время сессии:",
      },
    },
    psychologistReg: {
      emailCodeSpam: "Если вы не видите письмо, проверьте папку Спам",
      emailCode:
        "Вам был отправлен код на указанную почту, введите его в поле выше, пожалуйста.",
      generalInformation: "Общая информация",
      mainTreatmentMethod: "Основной метод",
      workExperience: "Опыт работы",
      aboutInfo: "Расскажите о себе",
      further: "Далее",
      back: "Назад",
      apply: "Подать заявку",
      stepOne: {
        desk: "Некоторые поля необходимо заполнить на 2 языках",
        phoneRules: 'Поле "телефон" обязательно при заполнении',
        phoneRules2: 'Поле "телефон" должно содержать только цыфры',
        birthRule:
          'Поле "дата рождения" должно быть в формате "год-месяц-день"',
        firstName: "Имя",
        secondName: "Фамилия",
        patronymic: "Отчество",
        dateOfBirth: "Дата рождения",
        citizenship: "Гражданство",
        phone: "Телефон",
        university: "Название ВУЗа",
        faculty: "Название факультета",
        speciality: "Специальность",
        scienseDegree: "Академическая или ученая степень",
        yearOfEnding: "Год окончания",
        education: "Образование",
        photoDesc:
          "Пожалуйста, прикрепите фотографии развернутых дипломов и сертификатов, подтверждающих обучение",
        diploma_and_certificates: "Дипломы и сертификаты",
        nameRules: 'Поле "Имя" обязательно при заполнении',
        lastnameRules: 'Поле "Фамилия" обязательно при заполнении',
        emailRules1: "E-mail должен быть валидным",
        emailRules2: 'Поле "E-mail" обязательно при заполнении',

        educationRules: 'Поле "Образование" обязательно при заполнении',
        universityRules: 'Поле "Название ВУЗа" обязательно при заполнении',
        facultyRules: 'Поле "Название факультета" обязательно при заполнении',
        specialityRules: 'Поле "Специальность" обязательно при заполнении',
        scienceDegreeRules:
          'Поле "Академическая или ученая степень" обязательно при заполнении',

        yearOfEndingRules1: 'Поле "Год окончания" обязательно при заполнении',
        yearOfEndingRules2: 'Поле "Год окончания" должно содержать 4 символа',
        citizenshipRules: 'Поле "Гражданство" обязательно при заполнении',

        bachelors: "Бакалавр",
        master: "Магистр",
        doctoral: "Доктор наук",
        candidate_of_sciences: "Кандидат наук",
        diploma_and_certificates_desk: "Основные и дополнительные",
      },
      stepTwo: {
        another: "Другое",
        mainTreatmentMethod: "Выберите Ваш основной метод:",
        additionalEducation:
          "Выберите, если у вас есть дополнительное образование, касающееся следующих тем:",
        additionalEducationDesk:
          "Напишите, пожалуйста, в какой области у вас есть дополнительное образование?",
        problems: "Выберите, проблемы с которыми вы можете помочь:",

        art_therapy: "Арт-терапия",
        positive_psychotherapy: "Позитивная психотерапия",
        client_centered_psychotherapy: "Клиент-центрированная психотерапия",
        cognitive_behavioral_therapy: "Когнитивно-поведенческая терапия",
        body_therapy: "Телесная терапия",
        psychoanalysis: "Психоанализ",
        jungian_analysis: "Юнгианский анализ",
        transactional_analysis: "Транзактный анализ",
        family_therapy: "Семейная терапия",
        gestalt_therapy: "Гештальт-терапия",
        psychodrama: "Психодрама",
        analytical_psychology: "Аналитическая психология",
        symboldrama: "Символдрама",
        existential_analysis: "Экзистенциальный анализ",

        addictions: "Зависимости",
        sexology: "Сексология",
        extreme_situations: "Экстремальные ситуации, ПТСР",

        lifeEvent: "События жизни",
        condition: "Состояние",
        relationship: "Отношение",
        career: "Работа, карьера, учеба",

        relocate_emigration: "Переезд, эмиграция",
        financial_changes: "Финансовые изменения",
        betrayal: "Измена",
        illness: "Болезнь, своя или близких",
        pregnancy: "Беременность, рождение ребёнка",
        divorce: "Разрыв отношений, развод",
        violence: "Насилие",
        loss_of_a_loved_one: "Утрата близкого человека",

        stress: "Стресс",
        panic_attacks: "Панические атаки",
        low_self_esteem: "Низкая самооценка",
        sleep_problems: "Проблемы со сном",
        emotional_addiction: "Эмоциональная зависимость",
        hypochondria: "Ипохондрия, переживание о здоровье",
        alcohol_drugs_addiction: "Зависимость, от алкоголя, наркотиков",
        anxiety: "Тревожность",
        breakdown: "Упадок сил",
        mood_swings: "Перепады настроения",
        irritability: "Раздражительность",
        feelings_of_loneliness: "Ощущение одиночества",
        social_network_addiction: "Зависимость от социальных сетей",
        eating_disorders: "Расстройство пищевого поведения",

        with_partner: "С партнёром",
        with_children: "С детьми",
        sexual: "Сексуальные",
        with_parents: "С родителями",
        common: "В целом, с окружающими",

        stress_at_work: "Стресс, выгорание",
        procrastination: "Прокрастинация",
        no_goal: "Отсутствие цели",
        lack_of_motivation: "Недостаток мотивации",
        cannot_find_myself: '"Не знаю, чем хочу заниматься"',
        job_loss_change: "Смена, потеря работы",
      },
      stepThree: {
        year: "год",
        years: "лет",
        years2: "года",
        years3: "года",
        workExperienceLabel: "Опыт работы",
        workExperience:
          "Сколько лет вы консультируете? За деньги, не в рамках учебной программы.",
        additionalProgram:
          "Какие дополнительные программы прошли или проходите на данный момент?",
        additionalProgramLabel: "Полное название и количество пройденных часов",
        selfTherapy: "Проходите ли вы личную психотерапию?",
        regularSupervision: "Проходите ли вы регулярно супервизии?",
        rules: "Поле обязательно при заполнении",
        consultingExperienceRules1: "Некорректный ввод",
        consultingExperienceRules2: "Пожалуйста введите правдивые данные",
        coursesRules: "Это поле должно содержать не менее 10 символов",
        yes: "Да",
        no: "Нет",
      },
      stepFour: {
        swallWriteToDB: "Создаем учетную запись",
        aboutInfo:
          "Расскажите о себе в свободной форме. Что считаете нам нужно узнать о вас, чтобы понять, какой вы специалист?",
        warning: "Эта информация будет отображаться на сайте",
        FOP: "Вы зарегистрированы как ФОП?",
        FOPLabel: "Укажите реквизиты",
        notWorkingWith: "С чем вы не работаете?",
        infoChannel: 'Как вы узнали о наборе терапевтов в сервис "Платформа"?',
        clientsQuantity:
          'Какое количество клиентов вы готовы взять на "Платформа"?',
        from: "От",
        to: "До",
        mediaLinksTitle: "Укажите ссылки на профили в социальных сетях",
        photoTitle: "Прикрепите вашу фотографию",
        photoLabel: "Ваше фото",
        requirements: "Требования к фото:",
        requirements1: "Цветное",
        requirements2: "Лицо по центру и хорошо освещено",
        requirements3: "Размер не менее 1МБ",
        requirements4: "В формате (название).jpg",
        rules: "Поле обязательно при заполнении",
        socialMediaLinksMin50: "Это поле должно содержать не менее 10 символов",
        socialMediaLinks2k: "Это поле должно содержать не более 2000 символов",
        textAreaValidationRulesMin50:
          "Это поле должно содержать не менее 50 символов",
        textAreaValidationRules:
          "Это поле должно содержать не более 2000 символов",
        entrepreneurRules: "Это поле должно содержать не менее 10 символов",
      },
    },
    footer: {
      questions: "Остались вопросы? ",
      contacts: "Мы всегда на связи",
      legal: {
        mainText:
          ' <b>Важный момент.</b> "Платформа" — это не скорая психологическая\n' +
          "            помощь. Если у вас серьезные, угрожающие вашей жизни проблемы,\n" +
          "            которые требуют немедленного решения, вам лучше обратиться в\n" +
          "            какую-либо из этих организаций.",
        userAgreementPart1: "Регистрация на сайте означает согласие с",
        userAgreementLink: "пользовательским соглашением.",
        userAgreementPart2: "Политика в отношении ",
        userAgreementLink2: "обработки и защиты персональных данных",
        terms:
          "При использовании любых материалов этого сайта, ссылка на источник обязательна.",
        protected: "Все права защищены!",
      },
      footerMenu: {
        FAQ: "ВОПРОСЫ-ОТВЕТЫ",
        Contacts: "КОНТАКТЫ",
        workOffers: "РАБОТА В ПЛАТФОРМА",
        blog: "БЛОГ",
        ourPsychologists: "НАШИ ПСИХОЛОГИ",
        forBusiness: "ПЛАТФОРМА ДЛЯ БИЗНЕСА",
      },
    },
  },
  view: {
    blog: {
      search: "Поиск по статьям",
      categories: "Категории",
      title: "Блог",
      subtitle: "Данный раздел находится в разработке.",
      localisationWarning: "Статья написана на 1-м языке",
      posted: "Опубликован",
      close: "Закрыть",
      readMore: "Читать статью полностью",
      nextPost: "Следующая статья",
      previousPost: " Предыдущая статья",
    },
    signIn: {
      title:
        "Пожалуйста, введите свой номер телефона с кодом страны, мы пришлём вам одноразовый пароль",
      termsPart1: "Вводя свой номер, вы принимаете условия, ",
      termsPart2: "пользовательского соглашения ",
      termsPart3: "даёте согласие на ",
      termsPart4: "обработку персональных данных ",
      termsPart5:
        "и даёте согласие на получение СМС-паролей, а также иных информационных и сервисных сообщений на указанный номер телефона.",
      code: "Получить код",
      loading: "Загрузка...",
      countrySelectorLabel: "Код страны",
      countrySelectorError: "Ошибка странны",
      phoneNumberLabel: "Номер телефона",
      example: "Пример :",
    },
    verify: {
      title: "ВВЕДИТЕ КОД",
      desk: "Код действителен в течение 10 минут. Код отправлен!",
      resendCode: "Отправить код повторно",
      resendCodeDesk:
        "Срок действия временного кода истек. Отправить код повторно?",
      accept: "Подтвердить",
      loading: "Загрузка...",
      viberCode1: "Не пришел код ?",
      viberCode2: "Отправить код на Viber",
    },
    registration: {
      welcomeMessagePsyh:
        "Если вы психолог и хотите стать частью нашей команды",
      welcomeMessagePatient:
        "Если вы хотите найти именно своего психолога и решить свой запрос",
      desk: "Для начала работы с Платформой необходимо пройти регистрацию",
      patient: "Регистрация для клиента",
      psychologist: "Регистрация для психолога",
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    },
    emailRegistration: {
      title: "Введите Ваш E-mail",
      desk: 'Всё общение с сервисом "Платформа" происходит через письма на почту. Мы пришлём Вам письмо с итогом по Вашей заявке.',
      accept: "Подтвердить",
      loading: "Загрузка...",
      emailRules1: 'Поле "E-mail" обязательно при заполнении',
      emailRules2: "E-mail должен быть валидным",
    },
    auth: {
      logout: "Ви успішно були розлогінені!",
      psychologist: {
        Schedule: {
          title: "Расписание",
          subtitle:
            "Заполните свой рабочий график, когда вы готовы рабоать с клиентами Платформы.",
          description:
            "Расписание отображается в формате - текущий день + 6 дней.",
          btnSave: "Сохранить",
          btnEdit: "Редактировать",
        },
      },
    },
  },
  developmentSection: {
    title: "Данный раздел находится в разработке.",
  },
  pageNotFound: {
    Oops: "Уупс..",
    description: "Страница которую Вы ищите, не существует. ",
  },
};
